import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SubscriptionPage from "./components/SubscriptionPage";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import NotificationHistory from "./components/NotificationHistory";
import LeadPage from "./components/LeadPage";

/**
 * Firebase configuration
 */
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

/**
 * Initialize firebase
 */
const app = initializeApp(firebaseConfig);
getAnalytics(app);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<NotificationHistory />} />
        <Route path="/subscribe/:token" element={<SubscriptionPage />} />
        <Route path="/lead/:notificationUuid" element={<LeadPage />} />
      </Routes>
    </Router>
  );
}

export default App;
