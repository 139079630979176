import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getMessaging, getToken } from 'firebase/messaging';
import { useNotificationBroker } from './NotificationBroker';
import {
  Button,
  CircularProgress,
  Typography,
  Container,
  Box,
  Icon
} from '@mui/material';
import Alert from '@mui/material/Alert';

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [invitation, setInvitation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subscribedStatus, setSubscribedStatus] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [navigating, setNavigating] = useState(false);

  const {
    serviceWorkerReady,
    permissionStatus,
    requestNotificationPermission,
    setPermissionStatus
  } = useNotificationBroker();

  useEffect(() => {
    // Get the invitation data
    fetch(`${apiBaseUrl}/agent/invitations/${token}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Invalid invitation link');
        }
        return response.json();
      })
      .then((data) => {
        setInvitation(data);
        setLoading(false);
        localStorage.setItem('invitationData', JSON.stringify(data));
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [token, apiBaseUrl]);

  // Handle the subscription event
  const handleSubscribe = async () => {
    setLoading(true);
    if (serviceWorkerReady && permissionStatus === 'granted') {
      const messaging = getMessaging();
      try {
        const currentToken = await getToken(messaging);
        if (currentToken) {
          // Subscribe to the organization's events
          fetch(`${apiBaseUrl}/agent/invitations/subscribe/${token}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fcmToken: currentToken }),
          })
            .then((response) => response.json())
            .then((data) => {
              setLoading(false);
              if (data.status === 200 && data.error === false) {
                setSubscribedStatus(`Success! You are now subscribed to ${invitation.organization.name} organization.`);
                setNavigating(true);
                setTimeout(() => {
                  navigate('/');
                }, 2000);
              } else {
                setSubscribedStatus('Failed to subscribe. Please try again later.');
              }
            })
            .catch((error) => {
              setLoading(false);
              setSubscribedStatus('An error occurred. Please try again later.');
            });
        } else {
          setLoading(false);
          console.log('No registration token available');
        }
      } catch (err) {
        setLoading(false);
        console.error('An error occurred while retrieving token', err);
      }
    }
  };

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        {navigating ? (
          <>
            <CircularProgress />
            <Typography variant="h6">Please wait...</Typography>
          </>
        ) : loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : subscribedStatus ? (
          <Alert severity={subscribedStatus.startsWith("Success") ? "success" : "error"}>
            {subscribedStatus}
          </Alert>
        ) : permissionStatus !== "granted" ? (
          <>
            <Alert severity="warning">
              Before you can continue, please grant notification permission.
            </Alert>
            <Button
              variant="contained"
              color="secondary"
              onClick={requestNotificationPermission}
              sx={{ mt: 2 }}
            >
              Allow Notifications
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h4" component="h1" gutterBottom>
              Subscribe to {invitation?.organization?.name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Once subscribed, you will receive notifications from <span style={{fontWeight: 'bold'}}>{invitation?.organization?.name}</span> organization.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubscribe}
              startIcon={<Icon>notifications</Icon>}
            >
              Subscribe
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default SubscriptionPage;
