import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Box,
  CircularProgress,
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import moment from "moment";

const LeadPage = () => {
  const { notificationUuid } = useParams();
  const [loading, setLoading] = useState(true);
  const [leadData, setLeadData] = useState({});
  const [notificationData, setNotificationData] = useState({});
  const [error, setError] = useState(null);
  const invitationData = JSON.parse(localStorage.getItem("invitationData"));
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const hasRun = useRef(false);

  useEffect(() => {
    if (!hasRun.current) {
      fetch(`${apiBaseUrl}/agent/lead/${notificationUuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Data:", data);
          setLeadData(data.lead);
          setNotificationData(data.notification);
          setLoading(false);

          if (!data.lead.assigned) {
            fetch(`${apiBaseUrl}/agent/lead/${notificationUuid}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                assigned: true,
                agentUuid: invitationData.agent.uuid,
              }),
            })
              .then((updateResponse) => updateResponse.json())
              .then((updateData) => {
                console.log("Lead updated:", updateData);
              })
              .catch((updateErr) => {
                console.error(
                  "An error occurred while updating the lead:",
                  updateErr
                );
              });
          }
          hasRun.current = true;
        })
        .catch((err) => {
          setError("An error occurred while fetching the lead.");
          setLoading(false);
        });
    }
  }, []);

  return (
    <Container>
      <Box my={4}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Paper elevation={1}>
            <Box p={3} position="relative">
              {notificationData.rawData.notification.logo && (
                <Box position="absolute" top={0} right={0} p={2}>
                   <img
                    alt="Company Logo"
                    src={notificationData.rawData.notification.logo}
                    style={{ width: 120, height: 'auto', objectFit: 'contain' }}
                  />
                </Box>
              )}
              <Box mb={2}>
                <Typography
                  variant="h5"
                  component="h1"
                  color="primary"
                  sx={{
                    color:
                      notificationData.rawData.notification.title?.color ||
                      "inherit", // Dynamic title color
                  }}
                >
                  {notificationData.rawData.notification.title?.text ||
                    notificationData.rawData.notification.title}
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  color="textSecondary"
                  sx={{
                    color:
                      notificationData.rawData.notification.message?.color ||
                      "inherit", // Dynamic body color
                  }}
                >
                  {moment(notificationData.createdAt).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  gutterBottom
                  sx={{ mt: 2 }}
                >
                  {notificationData.message?.text || notificationData.message}
                </Typography>
              </Box>

              {notificationData.showLeadData ? (
                <Box mt={2}>
                  <Divider />
                  <Box mt={2}>
                    <Typography variant="body1" component="p" gutterBottom>
                      <strong>First Name:</strong> {leadData.firstName}
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                      <strong>Last Name:</strong> {leadData.lastName}
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                      <strong>Mobile Number:</strong> {leadData.mobileNumber}
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                      <strong>Email Address:</strong> {leadData.emailAddress}
                    </Typography>
                  </Box>
                </Box>
              ) : null}

              {leadData.assigned &&
              leadData.agentUuid !== invitationData.agent.uuid ? (
                <Box mt={2}>
                  <Alert severity="info">
                    This lead has already been opened.
                  </Alert>
                </Box>
              ) : null}
            </Box>
          </Paper>
        )}
      </Box>
    </Container>
  );
};

export default LeadPage;
