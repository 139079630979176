import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ContactlessIcon from "@mui/icons-material/Contactless";
import GppGoodIcon from "@mui/icons-material/GppGood";
import moment from "moment";
import { useNotificationBroker } from "./NotificationBroker";

const NotificationHistory = () => {
  const [loading, setLoading] = useState(true);
  const [notificationData, setNotificationData] = useState([]);
  const [error, setError] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const invitationData = JSON.parse(localStorage.getItem("invitationData"));
  const defaultListRefreshRate = process.env.DEFAULT_LIST_REFRESH_RATE || 5000;
  const organizationName =
    invitationData?.organization?.name || "Unknown Organization";
  let socketServerURL = process.env.REACT_APP_SOCKET_SERVER_URL || "";
  if (!socketServerURL) {
    console.error("Socket server host URL required.");
  }

  // Get the notification broker
  const {
    serviceWorkerReady,
    permissionStatus,
    requestNotificationPermission,
    setPermissionStatus,
  } = useNotificationBroker();

  // Fetch notifications
  const fetchNotifications = () => {
    fetch(`${apiBaseUrl}/agent/notifications`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ agentUuid: invitationData.agent.uuid }),
    })
      .then((response) => response.json())
      .then((data) => {
        setNotificationData(data);
        setLoading(false);
      })
      .catch((err) => {
        setError("An error occurred while fetching notifications.");
        setLoading(false);
      });
  };

  useEffect(() => {
    requestNotificationPermission();
    fetchNotifications();
  }, [defaultListRefreshRate]);

  useEffect(() => {
    if (permissionStatus === "default") {
      requestNotificationPermission();
    }
  }, [permissionStatus]);

  useEffect(() => {
    // Initialize WebSocket connection
    const ws = new WebSocket(`${socketServerURL}`);

    // Event handler for WebSocket connection opening
    ws.onopen = (event) => {
      console.log("WebSocket connection opened:", event);
    };

    // Event handler for receiving text data from WebSocket
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("WebSocket message received:", data);
      switch (data.type) {
        case "lead.new":
          fetchNotifications();
          break;
        case "lead.open":
          // Remove the notification from the list
          setNotificationData((prevState) => ({
            notifications: prevState.notifications.filter(
              (notification) =>
                notification.uuid !== data.data.notification.uuid
            ),
          }));
          break;
      }
    };

    // Event handler for errors in WebSocket
    ws.onerror = (event) => {
      console.error("WebSocket error:", event);
    };

    // Event handler for WebSocket connection closing
    ws.onclose = (event) => {
      console.log("WebSocket connection closed:", event);
    };

    // Close WebSocket connection when this component unmounts
    return () => {
      ws.close();
    };
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  // Handle the notification click (UI)
  const handleNotificationClick = (notification) => {
    window.open(`/lead/${notification.uuid}`, "_blank");
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <img
            src={`${process.env.PUBLIC_URL}/favicon.png`}
            alt="App Icon"
            width={32}
            height={32}
          />
          <Typography variant="h6" sx={{ ml: 1, fontSize: "18px" }}>
            Notification History
          </Typography>
          <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
            <GppGoodIcon />
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              {organizationName}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          pt: "4px",
        }}
      >
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <List sx={{ width: "100%", p: 0 }}>
            {notificationData.notifications.map((notification, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleNotificationClick(notification)}
                sx={{ borderBottom: "1px solid #e4e4e4" }}
                id={`notification-${notification.uuid}`}
              >
                <ListItemText
                  primary={notification.message}
                  secondary={moment(notification.createdAt).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                />
                <Tooltip title="Channel">
                  <Box
                    sx={{
                      ml: "auto",
                      p: 1,
                      borderRadius: 1,
                      bgcolor: "grey.200",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ContactlessIcon fontSize="small" />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {notification.channelName}
                    </Typography>
                  </Box>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </>
  );
};

export default NotificationHistory;
