import { useEffect, useState } from "react";
import { getMessaging, onMessage } from "firebase/messaging";

export const useNotificationBroker = () => {
  const [serviceWorkerReady, setServiceWorkerReady] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(Notification.permission);

  /**
   * Request notification permission
   */
  const requestNotificationPermission = async () => {
    console.log("Requesting permission...");  // Debugging line
    try {
      const permission = await Notification.requestPermission();
      console.log("Permission granted:", permission);  // Debugging line
      setPermissionStatus(permission);
    } catch (error) {
      console.log("Failed to request notification permission:", error);  // Debugging line
    }
  };
  

  useEffect(() => {
    // Register service worker
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          if (registration.active) {
            setServiceWorkerReady(true);
          }
        })
        .catch((error) => {
          console.error("Service Worker Registration Failed:", error);
        });

      navigator.serviceWorker.addEventListener("controllerchange", () => {
        setServiceWorkerReady(true);
      });
    }

    // Register onMessage listener
    if (serviceWorkerReady && permissionStatus === "granted") {
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
          icon: "/favicon.png",
          requireInteraction: true,
        };
        const notification = new Notification(notificationTitle, notificationOptions);
        notification.onclick = (event) => {
          event.preventDefault();
          window.open(`./lead/${payload.data.uuid}`, "_blank");
        };
      });
    }
  }, [serviceWorkerReady, permissionStatus]);

  return {
    serviceWorkerReady,
    permissionStatus,
    requestNotificationPermission,
    setPermissionStatus
  };
};
